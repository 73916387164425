<template>
  <div class="home" id="index">
    <headert></headert>
    <el-row class="content">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <!--  <swiper :options="swiperOptions">
          <swiper-slide v-for="(item,index) in slideList" :key="index">
            <a href>
              <img class="swiperIMg" :src="item.img" alt />
            </a>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper> -->
        <el-carousel :interval="6000" height="670px" @change="carouselChange">
          <el-carousel-item v-for="(item,index) in slideList" :key="index">
            <!--    <img style="height: 100%;width:100%;object-fit: cover;object-fit: cover;" class="bannerimg"
              :class="{'bannerimgscale':carouselIndex==index}" :src="item.img"> -->
            <img style="height: 100%;width:100%;object-fit: cover;object-fit: cover;" class="bannerimg"
                 :src="item.litpic" @click="gotoPage(item)">
          </el-carousel-item>
        </el-carousel>
      </el-col>

    </el-row>
    <el-row class="content padding-tb70" type="flex" justify="center" align='center'>
      <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
        <div class="index-infoBox">
          <el-row class="content" :gutter='10' type="flex" justify="center" align='center' style="flex-wrap: wrap;">
            <el-col :xs="23" :sm="23" :md="23" :lg="12" :xl="12">
              <div class="index-box " style="padding-right: 20px;">
                <div class="index-title">
                  <h3>{{ lang == 'zh' ? siteVar.web_title : siteVar.web_attr_22 }}</h3>

                  <!-- <h4>WEIGA Cliques</h4> -->
                </div>
                <div class="index-font">
                  <p>
                    {{ lang == 'zh' ? siteVar.web_attr_23 : siteVar.web_attr_29 }}
                  </p>

                </div>

              </div>
            </el-col>
            <el-col :xs="23" :sm="23" :md="23" :lg="12" :xl="12">
              <div v-if="siteVar.web_attr_28" class="videoBox">
                <video width="100%" height="302" controls loop :autoplay="!isMobile" :poster='siteVar.web_attr_28'>
                  <!-- <source src="movie.ogg" type="video/ogg"> -->
                  <source :src="siteVar.web_attr_28" type="video/mp4">
                  <!-- <source src="movie.webm" type="video/webm"> -->
                  <object :data="siteVar.web_attr_28" width="320" height="240">
                    <!-- <embed width="320" height="240" src="movie.swf"> -->
                  </object>
                </video>
              </div>

            </el-col>

          </el-row>
        </div>
        <div class="index-imgListBox flex hidden-xs">

          <div class="index-imgOne   " data-wow-delay="0.1s" @mouseover="mouseoverImg(1)"
               :class="{'Active-imgOne':mouseOne==1}">
            <!--            集团介绍-->
            <router-link to="/WJgroupIntroduce">
              <div class="index-imgOneActive" :class="{'index-imgOneActiveto':mouseOne==1}">
                <img src="../assets/image/Property/Slice1.png" alt="">
                <h4 class="index-imgOneTitle">{{ $t('index.Introduction') }}</h4>
                <!-- <p class="index-imgOneETitle">About Weiga Realty</p> -->
              </div>
              <div class="index-imgOneNothing" :class="{'index-imgOneNothingto':mouseOne==1}">
                <img src="../assets/image/Property/Slice1.png" alt="">
                <h4 class="index-imgOneTitle">{{ $t('index.Introduction') }}</h4>
                <!-- <p class="index-imgOneETitle">About Weiga Realty</p> -->
              </div>
            </router-link>
          </div>

          <div class="index-imgOne index-imgOne2 " data-wow-delay="0.2s" @mouseover="mouseoverImg(2)"
               :class="{'Active-imgOne':mouseOne==2}">
            <!--      资质荣誉      -->
            <router-link to="/WJgroupHonor">
              <div class="index-imgOneActive" :class="{'index-imgOneActiveto':mouseOne==2}">
                <img src="../assets/image/Property/Frame4.png" alt="">
                <h4 class="index-imgOneTitle">{{ $t('index.honor') }}</h4>
                <!-- <p class="index-imgOneETitle">Honor</p> -->
              </div>
              <div class="index-imgOneNothing" :class="{'index-imgOneNothingto':mouseOne==2}">
                <img src="../assets/image/Property/Frame4.png" alt="">
                <h4 class="index-imgOneTitle">{{ $t('index.honor') }}</h4>
                <!-- <p class="index-imgOneETitle">Honor</p> -->
              </div>
            </router-link>
          </div>

          <div class="index-imgOne index-imgOne3 " data-wow-delay="0.3s" @mouseover="mouseoverImg(3)"
               :class="{'Active-imgOne':mouseOne==3}">
            <router-link to="/WJgroupTeam">
              <!--              管理团队-->
              <div class="index-imgOneActive" :class="{'index-imgOneActiveto':mouseOne==3}">
                <img src="../assets/image/Property/Frame2.png" alt="">
                <h4 class="index-imgOneTitle">{{ $t('index.team') }}</h4>
                <!-- <p class="index-imgOneETitle">Team Management</p> -->
              </div>
              <div class="index-imgOneNothing" :class="{'index-imgOneNothingto':mouseOne==3}">
                <img src="../assets/image/Property/Frame2.png" alt="">
                <h4 class="index-imgOneTitle">{{ $t('index.team') }}</h4>
                <!-- <p class="index-imgOneETitle">Team Management</p> -->
              </div>
            </router-link>
          </div>
          <div class="index-imgOne index-imgOne4 " data-wow-delay="0.4s" @mouseover="mouseoverImg(4)"
               :class="{'Active-imgOne':mouseOne==4}">
            <router-link to="/personnelJoinUs">
              <!--                联系我们-->
              <div class="index-imgOneActive " :class="{'index-imgOneActiveto':mouseOne==4}">
                <img src="../assets/image/Property/Frame3.png" alt="">
                <h4 class="index-imgOneTitle">{{ $t('index.contactUs') }}</h4>
                <!-- <p class="index-imgOneETitle">Contoct Us</p> -->
              </div>
              <div class="index-imgOneNothing" :class="{'index-imgOneNothingto':mouseOne==4}">
                <img src="../assets/image/Property/Frame3.png" alt="">
                <h4 class="index-imgOneTitle">{{ $t('index.contactUs') }}</h4>
                <!-- <p class="index-imgOneETitle">Contoct Us</p> -->
              </div>
            </router-link>
          </div>
        </div>
      </el-col>

    </el-row>
    <div class="index-industryBox">
      <el-row class="content " type="flex" justify="center" align='center'>
        <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
          <div class="flex align-start index-industry-wrap">
            <div class="index-industryLeft">
              <div class="index-box " style="padding-right: 20px;">
                <div class="index-title">
                  <h3>{{ chanyeDesc.typename }}</h3>
                </div>
                <div class="index-font">
                  <p>
                    {{ chanyeDesc.idea }}
                  </p>

                </div>
              </div>
              <!--              pc端显示-->
              <!--              维佳文娱-->
              <div v-if="chanyeList&&chanyeList.length>0" class="index-industryImg index-industryImg1 hidden-xs">
                <img :src="chanyeList[0].litpic" alt="">
                <div class="index-industryImgContent index-industryImg1C flex flex_column align-end justify_center">
                  <h6>{{ chanyeList[0].title }}</h6>
                  <!--                  <p v-html="chanyeList[0].describe"></p>-->
                </div>
              </div>
              <!--              维佳商贸-->
              <div v-if="chanyeList&&chanyeList.length>1" class="index-industryImg index-industryImg2 hidden-xs">
                <img :src="chanyeList[1].litpic" alt="">
                <div class="index-industryImgContent index-industryImg2C flex flex_column align-start justify_center">
                  <h6>{{ chanyeList[1].title }}</h6>
                  <!--                  <p v-html="chanyeList[1].describe" style="width: 59%;"></p>-->
                </div>
              </div>
            </div>
            <div class=" index-industryRight">
              <!--              维佳地产-->
              <div v-if="chanyeList&&chanyeList.length>2" class="index-industryImg index-industryImg3 hidden-xs">
                <img :src="chanyeList[2].litpic" alt="">
                <div class="index-industryImgContent index-industryImg3C flex flex_column align-end justify_center">
                  <h6>{{ chanyeList[2].title }}</h6>
                  <!--                  <p v-html="chanyeList[2].describe"></p>-->
                </div>
              </div>
              <!--              维佳产业园-->
              <div v-if="chanyeList&&chanyeList.length>3" class="index-industryImg index-industryImg4 hidden-xs">
                <img :src="chanyeList[3].litpic" alt="">
                <div class="index-industryImgContent index-industryImg4C flex flex_column align-end justify_center">
                  <h6>{{ chanyeList[3].title }}</h6>
                  <!--                  <p v-html="chanyeList[3].describe"></p>-->
                </div>
              </div>
            </div>
            <!--            移动端展示  -->
            <div class="hidden-sm-and-up">
              <!--              维佳地产 -->
              <div v-if="chanyeList&&chanyeList.length>2" class="index-industryImg index-industryImg3 ">
                <img :src="chanyeList[2].litpic" alt="">
              </div>
              <div v-if="chanyeList&&chanyeList.length>0" class="index-industryImg index-industryImg1 ">
                <img :src="chanyeList[0].litpic" alt="">
              </div>
              <div v-if="chanyeList&&chanyeList.length>3" class="index-industryImg index-industryImg4 ">
                <img :src="chanyeList[3].litpic" alt="">
              </div>
              <div v-if="chanyeList&&chanyeList.length>1" class="index-industryImg index-industryImg2 ">
                <img :src="chanyeList[1].litpic" alt="">

              </div>
            </div>

          </div>
        </el-col>
      </el-row>
    </div>


    <!-- 底部 -->
    <footerb></footerb>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      swiperOptions: {
        autoplay: {
          delay: 3000,
          disableOnInteraction: true
        },

        loop: true,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        // fade: {
        //   slideShadows: true,
        //   shadow: true,
        //   shadowOffset: 100,
        //   shadowScale: 0.6,
        //   crossFade: true
        // },

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true
          // progressbarOpposite: true,
        }
      },
      slideList: [],
      mouseOne: 1,
      IndustryType: 1,
      industryCointentLeft: '0px',
      carouselIndex: 0,
      siteVar: {},
      chanyeList: [],
      chanyeDesc: {},
      isMobile: false
    };
  },
  computed: {
    username() {
      return this.$t('username')
    },
    lang() {
      return window.localStorage.getItem('language')
    },
  },
  created() {
    this.getData();
  },
  mounted() {
    new this.$wow.WOW().init()
    window.addEventListener("resize", this.widthChange);

  },
  destroyed() {
    window.removeEventListener('resize', this.widthChange)
  },
  methods: {
    widthChange(e) {
      // 获取屏幕宽度
      var w = document.documentElement.clientWidth;
      if (w <= 750) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
    gotoPage(e) {
      console.log(e)
      if (e.links) {
        window.open(e.links)
      }

    },
    fixWidth() {
      // this.industryCointentLeft = this.$refs.industryCointentBox.clientWidth + 'px'
      // console.log("this.industryCointentLeft====" + this.industryCointentLeft);
      // this.$refs.Carousel.setActiveItem(this.IndustryType)
    },
    //获取内容
    getData() {
      let api = '?m=api&c=v1.Api&a=index&_ajax=1';
      this.$request.post(api, {
        apiAdv_1: `ekey=1&pid=1`, //获取广告列表
        apiGlobal: `ekey=1,web_attr_23_en,web_title_en`, //获取全局变量
        apiArclist_1: 'ekey=1&typeid=119&limit=4&addfields=describe,title_en,describe_en,typename_en,web_attr_23_en,web_title_en', //获取栏目文章列表
      }).then(res => {
        let slideList = this.$utils.translate(res.apiAdv[1].data);

        if (this.lang == 'zh') {
          this.slideList = slideList.filter(item => item.en == '0')
        } else {
          this.slideList = slideList.filter(item => item.en == '1')
        }

        this.siteVar = this.$utils.translate(res.apiGlobal[1].data);
        this.chanyeList = this.$utils.translate(res.apiArclist[1].data);
        this.chanyeDesc = this.$utils.translate(res.apiArclist[1].arctype);
        this.fixWidth();


      })
    },
    carouselChange(i) {
      this.carouselIndex = i
    },
    handleIndustry(val) {
      this.IndustryType = val
      // console.log(val);
      this.$refs.Carousel.setActiveItem(val - 1)
    },
    prevTo() {
      this.$refs.Carousel.prev()
    },
    nextTo() {
      this.$refs.Carousel.next()
    },
    mouseoverImg(val) {
      this.mouseOne = val
    },
    onSwiper(swiper) {
      // console.log(swiper);
    },
    onSlideChange() {
      // console.log("slide change");
    }
  }
};
</script>
<style lang="less" scoped>
.bannerimg {
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  /* Safari 和 Chrome */
}

.bannerimgscale {
  animation: myfirst 6s infinite;
}

@keyframes myfirst {
  0% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1.1, 1.1);
  }
}


.swiperIMg {
  width: 100%;
  height: auto;
}


.swiper-container {
  width: 100%;
  max-height: 670px;
}

.content {
  overflow: hidden;
}

.index-infoBox {
  background: url(../assets/image/bgin.png) no-repeat;
  background-position: bottom right;
  background-size: 100%;
  padding-bottom: 60px;
}

.index-title {
  margin-bottom: 35px;

  img {
    width: 24px;
  }
}

.index-title h3 {
  font-size: 36px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #202C38;
  line-height: 46px;
  margin-bottom: 10px;
}

.index-title h4 {

  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #AF8E68;
  line-height: 40px;
}

.index-font p {

  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000;
  line-height: 36px;
}

.index-imgListBox {
}

.index-imgOne {
  width: 25%;
  margin: 0 3px;
  overflow: hidden;
  height: 320px;
  background: url(../assets/image/Property/img5.png) no-repeat right;
  background-size: cover;
  color: #fff;
  position: relative;
  transition: 1s;


}

.Active-imgOne {
  // flex: 1;
  width: 45%;

}

.index-imgOne2 {
  background: url(../assets/image/Property/img6.png) no-repeat right;
  background-size: cover;
}

.index-imgOne3 {
  background: url(../assets/image/Property/img7.png) no-repeat right;
  background-size: cover;
}

.index-imgOne4 {
  background: url(../assets/image/Property/img9.png) no-repeat right;
  background-size: cover;
}

.index-imgOneActiveto {
  opacity: 0 !important;
}


.index-imgOneNothingto {
  opacity: 1 !important;
}

.index-imgOne .index-imgOneActive {
  opacity: 1;
  padding: 40px 30px;
  transition: 0.7s;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background: rgba(0, 0, 0, 0.5);

  img {
    width: 24px;
  }
}

.index-imgOne .index-imgOneNothing {
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);

  img {
    width: 32px;
    height: 32px;
  }
}

.index-imgOne {
  cursor: pointer;
}

.index-imgOne .index-imgOneTitle {
  margin-top: 7px;
  font-size: 24px;
  font-weight: bold;
  position: relative;
  line-height: 33px;

  // font-family: OPPOSans-Medium, OPPOSans;
  font-weight: 500;
  color: #FFFFFF;
}

// .index-imgOne .index-imgOneTitle::after {
//   content: '';
//   width: 24px;
//   height: 3px;
//   background-color: #fff;
//   position: absolute;
//   top: -16px;
//   left: 0;
// }

.index-imgOne .index-imgOneETitle {
  margin-top: 11px;
  font-size: 12px;
  // font-family: OPPOSans-Medium, OPPOSans;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 16px;
}

.index-industryBox {
  background: linear-gradient(180deg, #E9ECF1 0%, rgba(233, 236, 241, 0) 100%);
  // background: url(../assets/image/indexBG.png) no-repeat;
  // background-size: cover;
  padding: 125px 0 160px;
}

.index-industryBox ::v-deep .el-carousel__indicators {
  display: none;
}

.me-el-carousel__arrow {
  width: 45px;
  height: 45px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0;
  color: #AF8E68;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
}

.me-el-carousel__arrow:hover {
  background-color: #AF8E68 !important;
  color: #fff !important;
}

.me-el-carousel__arrow:hover {
  opacity: 0.8;
}

.me-el-carousel__arrowActive {
  background-color: #AF8E68 !important;
  color: #fff !important;
}

.me-el-carousel__arrowBox {
  display: flex;
  position: absolute;
  bottom: 0px;
  right: -45px;
  z-index: 9999999;
}

.index-industry-tagbox {
  display: flex;
}

.index-industry-tagbox li {
  width: 140px;
  height: 40px;
  line-height: 38px;
  background: rgba(175, 142, 104, 0);
  border: 1px solid #AF8E68;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #AF8E68;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 76px;
  cursor: pointer;
}

.index-industry-tagbox li.activeLi {
  background: #AF8E68 !important;
  color: #fff !important;
}

.index-industry-cointentBox {
  position: relative;
  overflow: hidden;
  min-height: 300px;
  width: 100%;
}

.index-industry-cointent {
  position: absolute;
  top: 0;
  width: 100%;

}

.me-transition {
  transition: 2s;
}

.index-industry-cointentActive {
  left: 0 !important;
}


.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  color: #fff !important;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  color: #fff !important;
}

.index-industryLeft {
  width: 41%;
}

.index-industryLeft .index-box {
  width: 80%;
}

.index-industryRight {
  margin-left: 4px;
  flex: 1;
}

.index-industryImg1 {
  margin-top: 105px;
  padding-top: 62%;
  // background: url(../assets/image/indexp2.png) no-repeat;
  // background-size: cover;
}

.index-industryImg3 {
  padding-top: 62%;
  // background: url(../assets/image/indexp1.png) no-repeat;
  // background-size: cover;
}

.index-industryImg4 {
  margin-top: 4px;
  padding-top: 40%;
  width: 84% !important;
  // background: url(../assets/image/indexp3.png) no-repeat;
  // background-size: cover;
}

.index-industryImg4 p {
  text-align: center;
}

.index-industryImg1C {
  right: 0;
  top: 0;
  width: 45%;
  height: 100%;
  background: url(../assets/image/ibb1.png) no-repeat;
  background-size: cover;
}

.index-industryImg3C {
  right: 0;
  top: 0;
  width: 45%;
  height: 100%;
  background: url(../assets/image/ibb3.png) no-repeat;
  background-size: cover;
}

.index-industryImg4C {
  right: 0;
  top: 0;
  width: 41%;
  height: 100%;
  background: url(../assets/image/ibb4.png) no-repeat;
  background-size: cover;
}

.index-industryImg {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.index-industryImg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 1s;
}

.index-industryImg:hover img {
  transform: scale(1.1)
}

.index-industryImg2 {
  // background: url(../assets/image/indexp5.png) no-repeat;
  // background-size: cover;
  width: 82%;
  padding-top: 53%;
  margin-top: 4px;
  margin-left: 18%;
}

.index-industryImg2C {
  left: 0;
  top: 0;
  width: 40%;
  height: 100%;
  background: url(../assets/image/ibb2.png) no-repeat;
  background-size: cover;
}

.index-industryImg2C h6 {
  margin-right: 0;
  margin-left: 10px;
}

.index-industryImg2C p {
  margin-right: 0;
  margin-left: 10px;
  text-align: center;
}

.index-industryImgContent {
  position: absolute;

}

.index-industryImgContent h6 {
  // font-family: 'OPPOSans';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 1px;
  text-align: center;
  color: #FFFFFF;
  width: 60%;
  margin-right: 10px;
}

.index-industryImgContent p {
  margin-top: 20px;
  // font-family: 'OPPOSans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  width: 60%;
  color: #FFFFFF;
  margin-right: 10px;
  white-space: pre-line;
  text-align: center;
}


@media screen and (min-width: 320px) and (max-width: 767px) {
  #index ::v-deep .el-carousel__container {
    height: 380px !important;
  }

  .padding-tb70 {
    padding: 20px 0 !important;
  }

  #index .index-title h3 {
    font-size: 46px;
    line-height: 56px;
    margin-bottom: 0;
  }

  #index .index-title {
    margin-bottom: 15px;
  }

  #index .index-title h4 {
    font-size: 14px;
    line-height: 20px;
  }

  #index .index-font p {
    font-size: 34px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1F2D38;
    line-height: 54px;
    margin-bottom: 20px;
  }

  #index .index-box {
    padding: 0 !important;
  }

  .videoBox {
    margin-top: 10px;
  }

  .videoBox video {
    height: 548px;
  }

  .index-infoBox {
    padding-bottom: 20px;
  }

  .index-industry-tagbox {
    display: none;
  }

  .index-industry-cointentBox {
    margin-top: 20px;
    min-height: 250px;
  }

  .me-el-carousel__arrowBox {
    right: 0px !important;
  }

  .me-el-carousel__arrow:nth-child(1) {
    border-right: 1px solid #AF8E68 !important;
  }

  .index-industry-tagbox li {
    margin-bottom: 20px !important;
  }

  .index-industry-wrap {
    display: block;
  }

  .index-industryLeft {
    width: 100%;
  }

  .index-industryImg2 {
    width: 100%;
    margin-left: 0;
  }

  .index-industryRight {
    width: 100%;
    flex: 1 1;
  }

  #index .index-industryImg4 {
    width: 100% !important;
  }

  .index-industryImg1 {
    margin-top: 4px;

    padding-top: 53%;
  }

  .index-industryImg3 {
    padding-top: 53%;
  }

  .index-industryImg4 {
    padding-top: 53%
  }
}

</style>
